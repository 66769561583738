import React from 'react'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl } from '../../../utils/format'
import BlockContent from '@sanity/block-content-to-react'
import styles from './style.module.sass'

const bodySerializers = {
  marks: {
    link: props => (
      <a href={props.mark.href} target="_blank" rel="noreferrer">
        {props.children}
      </a>
    ),
  }
}

export default ({ body }) => {
  return (
    <div className={styles.body}>
      <div className={styles.wrap}>
        {body.content && body.content.map(item => (
          <div className={styles.item} key={item.key}>
            {item._type === 'body' && (
              <div className={styles.content}>
                <BlockContent blocks={item.body} serializers={bodySerializers} />
              </div>
            )}
            {item._type === '2columns' && (
              <div className={styles.columns}>
                <div className={styles.columnsImage}>
                  <img
                    {...srcSetProps(sanityImageUrl(item.image))}
                    alt={item.image?.caption}
                    className={styles.image}
                  />
                </div>
                <div className={styles.columnsText}>
                  <p className={styles.columnsTitle}>{item.title}</p>
                  {item.items && (
                    <>
                      {item.items.length === 1 ? (
                        <p className={styles.columnsContent}>{item.items[0]}</p>
                      ) : (
                        <div className={styles.columnsList}>
                          {item.items.map((step, index) => (
                            <div key={index} className={styles.columnsStep}>
                              <div className={styles.columnsStepIndex}>{index + 1}</div>
                              <p className={styles.columnsStepText} dangerouslySetInnerHTML={{ __html: step }} />
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                  <a href={item.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.button}>{item.buttonText}</a>
                </div>
              </div>
            )}
          </div>          
        ))}
      </div>
    </div>
  )
}
