import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import Header from '../components/SideEffectsPage/Header'
import Body from '../components/SideEffectsPage/Body'

export default ({ pageContext: { data } }) => (
  <Layout data={data}>
    <SEO {...data.seo} />
    <Header header={data.header} />
    <Body body={data.body} />
  </Layout>
)